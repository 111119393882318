import styled from '@emotion/styled'

import { CONFIG } from '@/ui/shared/styles'

/**
 * @TODO Precisa ser refatorado, quebrando em multiplos componentes e com seus respectivos estilos
 */
export const MediaBlockStyles = styled.div({
  '& .mediaBlock': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .mediaText, & .mediaContent, & .mediaBlock .mediaButton': {
    width: '100%',
  },

  '.mediaText': {
    padding: '0',
  },

  '& .mediaText > .title + .subtitle': {
    margin: '0.5rem 0 0',
  },

  '& .mediaText .m-content': {
    margin: '1rem 0 0',
  },

  '& .mediaContent': {
    maxWidth: '22rem',
  },

  '& .mediaContent img': {
    objectFit: 'contain',
  },

  '& .mediaBlock.m > .mediaContent': {
    width: '73.335vw',
  },

  '& .mediaBlock .mediaButton': {
    marginTop: '1rem',
    maxWidth: '20.5rem',
  },

  '& .mediaContent.withVideo': {
    maxWidth: '100%',
    position: 'relative',
  },

  '& .mediaText + .mediaContent': {
    margin: '1rem auto 0',
  },

  '& .cta': {
    width: '100%',
  },

  [CONFIG.media.lg]: {
    '& .mediaBlock': {
      display: 'grid',
      gridTemplateRows: '1rem 2fr 1rem',
      gridTemplateColumns: '352px 1fr',
      gridColumnGap: '2rem',
      alignItems: 'center',
    },

    '& .mediaBlock.m': {
      gridTemplateColumns: '288px 1fr',
    },

    '& .mediaContent': {
      gridColumn: '1/1',
      gridRow: '1/5',
    },

    '& .mediaBlock.m > .mediaContent': {
      width: '100%',
    },

    '& .mediaContent:not(.withVideo)': {
      margin: '0',
      maxWidth: '100%',
    },

    '& .mediaText, & .mediaBlock .mediaButton': {
      width: 'auto',
      gridColumn: '2/2',
      gridRow: '2/2',
    },

    '& .mediaBlock .mediaButton': {
      alignSelf: 'self-start',
      gridRow: '3/3',
    },

    '& .mediaBlock:not(.center)': {
      alignSelf: 'flex-start',
    },

    '& .mediaBlock.withButton': {
      gridTemplateRows: '1rem auto auto 1rem',
    },

    '& .mediaBlock.withButton .mediaText': {
      alignSelf: 'flex-end',
    },

    '& .mediaText + .mediaContent, & .mediaBlock.withButton .mediaText .title': {
      margin: '0',
    },

    '& .mediaBlock.withButton .mediaText .title + .subtitle': {
      margin: '0.5rem 0 0',
    },

    '& .mediaBlock.right': {
      gridTemplateColumns: '1fr 22rem',
    },

    '& .mediaBlock.m.right': {
      gridTemplateColumns: '1fr 18rem',
    },

    '& .mediaBlock.right .mediaContent': {
      gridColumn: '2/2',
    },

    '& .mediaBlock.right .mediaText, & .mediaBlock.right .mediaButton': {
      gridColumn: '1/1',
    },

    '& .mediaBlock.center': {
      gridTemplateRows: 'auto 1fr',
      gridTemplateColumns: '1fr',
    },

    '& .mediaBlock.center .mediaText, & .mediaBlock.center .mediaContent, & .mediaBlock.center .mediaButton':
      {
        gridColumn: '1',
      },

    '& .mediaBlock.center .mediaText': {
      gridRow: '1/1',
    },

    '& .mediaBlock.center .mediaContent': {
      gridRow: '2/2',
      marginTop: '1rem',
    },

    '& .mediaBlock.center .mediaButton': {
      gridRow: '3/3',
      margin: '1rem auto 0',
    },
  },
})
