import type { MutableRefObject } from 'react'

import { Image, type ImageProps, type MedusaImageData } from '@/legacy/components/Image'

import { VideoImage, type VideoImageProps } from './VideoImage'

export type Midia = {
  type: 'video' | 'image'
  image?: MedusaImageData
  video?: {
    url: string
    id?: string
  }
}

export interface MediaProps extends Midia, Pick<VideoImageProps, 'aspectRatio'> {
  id?: string
  className?: string
  width?: number
  height?: number
  layout?: ImageProps['layout']
  priority?: boolean
  ref?: MutableRefObject<HTMLElement | null>
}

const Media = ({ type, video, image, aspectRatio, ...props }: MediaProps) => (
  <>
    {type === 'video' && video && (
      <VideoImage image={image} aspectRatio={aspectRatio} {...props} {...video} />
    )}
    {type === 'image' && image && (
      // eslint-disable-next-line jsx-a11y/alt-text
      <Image data={{ ...image }} {...props} />
    )}
  </>
)

export default Media
