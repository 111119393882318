import type { ButtonModifier } from '@polycups/react'
import cx from 'classnames'

import { useRef } from 'react'

import type { CustomAnalyticsAttributes } from '@/legacy/blocks/constants'
import { ButtonLink } from '@/legacy/components/Button'
import type { Space } from '@/legacy/components/Container'
import { Media, type Midia } from '@/legacy/components/Media'
import { RichText } from '@/legacy/components/RichText'
import { SubHeading } from '@/legacy/components/Typography'
import { useViewBannerEvent } from '@/legacy/core/analytics/hooks'
import {
  extractCustomButtonEvent,
  extractCustomViewContentEvent,
} from '@/legacy/core/analytics/utils/extract-custom-event-props'
import { clearBuilderProps } from '@/legacy/core/utils/dom/clearBuilderProps'
import { decodeURL } from '@/legacy/core/utils/dom/decodeURL'
import { useViewContentEvent } from '@/legacy/hooks/useViewContentEvent'
import type { TitleProps } from '@/legacy/models'

import { MediaBlockStyles } from './MediaBlockStyles'

import { mediaBlockUtils } from '.'

export interface MediaBlockProps extends Midia {
  id?: string
  title: TitleProps
  subtitle: TitleProps
  position: 'right' | 'center' | 'left'
  space: Space
  size: 'm' | 'l'
  content: string
  hasButton: boolean
  layout: string
  button?: {
    label: string
    link: string
    rounded: boolean
    modifier: ButtonModifier
    ariaLabel?: string
    targetId?: string
  }
  analytics?: CustomAnalyticsAttributes
}

const MediaBlock = ({
  id,
  size,
  title,
  subtitle,
  content,
  position,
  space,
  hasButton,
  analytics,
  button,
  ...mediaProps
}: MediaBlockProps) => {
  clearBuilderProps(mediaProps, ['showImage'])

  const TitleComponent = mediaBlockUtils.getTitleComponent(size)
  const imageProps = {
    ...mediaProps,
    width: mediaBlockUtils.getMediaWidthByPosition(position),
    height: mediaBlockUtils.getMediaHeightByPosition(position),
  }
  const withImage = imageProps.type === 'image' && !!imageProps.image?.url
  const withVideo = imageProps.type !== 'image' && !!imageProps.video?.url

  const customButtonEvent = extractCustomButtonEvent(analytics)
  const customViewContentEvent = extractCustomViewContentEvent(analytics)

  const refBanner = useRef<HTMLDivElement>(null)
  const viewContentEvent = useViewBannerEvent()
  useViewContentEvent({
    contentRef: refBanner,
    viewContentEvent: () => viewContentEvent(customViewContentEvent),
  })

  return (
    <MediaBlockStyles className={cx('ecs-container', `eu-sm-${space}`, `eu-sp-${size}`)} id={id}>
      <div
        className={cx('mediaBlock', size, {
          [position]: position,
          withButton: hasButton,
        })}
      >
        {(title || subtitle || content) && (
          <div className="mediaText">
            {title && title.text && (
              <TitleComponent className="title" tag={title.tag || 'h3'}>
                {title.text}
              </TitleComponent>
            )}

            {subtitle && subtitle.text && (
              <SubHeading dark="medium" className="subtitle" tag={subtitle.tag || 'h4'}>
                {subtitle.text}
              </SubHeading>
            )}

            {content && (
              <RichText dark="medium" className="m-content" content={content} tag="div" />
            )}
          </div>
        )}

        <div
          ref={refBanner}
          className={cx('mediaContent', {
            withImage,
            withVideo,
          })}
        >
          <Media
            {...imageProps}
            aspectRatio={position === 'center' ? '16x9' : '4x3'}
            layout="responsive"
          />
        </div>

        {hasButton && button && (
          <div className="mediaButton">
            <ButtonLink
              event={customButtonEvent}
              source={decodeURL(button.link)}
              action={button.link ? 'link' : 'scroll'}
              value={button.targetId}
              rounded={button.rounded}
              aria-label={button.ariaLabel}
              modifier={button.modifier || 'solid'}
              size="lg"
              className="cta"
            >
              {button.label}
            </ButtonLink>
          </div>
        )}
      </div>
    </MediaBlockStyles>
  )
}

export default MediaBlock
