import { useEffect, useRef } from 'react'

import { useSelectButtonEvent, useSelectLinkEvent } from '@/legacy/core/analytics/hooks'

export const useRichTextAnalyticsEvents = () => {
  const ref = useRef<HTMLDivElement>(null)
  const selectLinkEvent = useSelectLinkEvent()
  const selectButtonEvent = useSelectButtonEvent()

  const handleClickLink = (event: MouseEvent) => {
    const itemText = (event.currentTarget as HTMLAnchorElement).textContent || ''
    selectLinkEvent({ label: itemText })
  }
  const handleClickButton = (event: MouseEvent) => {
    const itemText = (event.currentTarget as HTMLButtonElement).textContent || ''
    selectButtonEvent({ label: itemText })
  }

  useEffect(() => {
    if (!ref.current) return
    const links = ref.current.getElementsByTagName('a')
    const buttons = ref.current.getElementsByTagName('button')
    const hasLinks = links && links.length > 0
    const hasButtons = buttons && buttons.length > 0

    if (hasLinks) {
      Array.from(links).forEach((link) => {
        link.addEventListener('click', handleClickLink)
      })
    }

    if (hasButtons) {
      Array.from(buttons).forEach((button) => {
        button.addEventListener('click', handleClickButton)
      })
    }
  }, [ref])

  return ref
}
