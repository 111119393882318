import { LIST_SIZE } from '@/legacy/blocks/constants'
import { HeadingM, HeadingS } from '@/legacy/components/Typography'

const getMediaWidthByPosition = (position: 'right' | 'center' | 'left') =>
  position === 'center' ? 928 : 352

const getMediaHeightByPosition = (position: 'right' | 'center' | 'left') =>
  position === 'center' ? 522 : 264

const getTitleComponent = (size: 'm' | 'l' = 'l') => (size === LIST_SIZE.lg ? HeadingM : HeadingS)

const utils = {
  getMediaWidthByPosition,
  getMediaHeightByPosition,
  getTitleComponent,
}

export default utils
