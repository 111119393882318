import type { CustomAnalyticsAttributes } from '@/legacy/blocks/constants'

export function extractCustomButtonEvent(analytics?: CustomAnalyticsAttributes) {
  return {
    itemId: analytics?.buttonEvent?.itemId || '',
    label: analytics?.buttonEvent?.itemText || '',
    customProps: {
      action: analytics?.buttonEvent?.action || '',
      content_type: analytics?.buttonEvent?.content_type || '',
    },
  }
}

export function extractCustomViewContentEvent(analytics?: CustomAnalyticsAttributes) {
  return {
    label: analytics?.viewContentEvent?.itemText || '',
    customProps: {
      action: analytics?.viewContentEvent?.action || '',
    },
  }
}
