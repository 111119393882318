import { sanitize } from '@/legacy/core/utils/security'

import { BodyM, type TypographyProps } from '../Typography'

import { additionalAttributes } from './constants'
import { useRichTextAnalyticsEvents } from './useRichTextAnalyticsEvents'

interface RichTextProps extends Omit<TypographyProps, 'html' | 'children'> {
  typography?: (props: TypographyProps) => JSX.Element
  content: string
  className?: string
}

export const RichText = ({
  content,
  typography: Typography = BodyM,
  className,
  ...typographyProps
}: RichTextProps) => {
  const sanitizedContent = sanitize(content, {
    ADD_ATTR: additionalAttributes,
  })
  const ref = useRichTextAnalyticsEvents()
  return (
    <div ref={ref} className={className}>
      <Typography html={sanitizedContent} {...typographyProps} />
    </div>
  )
}

export default RichText
