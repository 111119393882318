import styled from '@emotion/styled'
import { useState } from 'react'

import { Icon } from '@/legacy/components/Icon'
import { Image, type ImageProps } from '@/legacy/components/Image'
import { YoutubeEmbed, type YoutubeEmbedProps } from '@/legacy/components/YoutubeEmbed'
import { youtubeVideoIDParser } from '@/legacy/components/YoutubeEmbed/utils'

export interface VideoImageProps extends YoutubeEmbedProps {
  className?: string
  layout?: ImageProps['layout']
  image?: ImageProps['data']
  width?: number
  height?: number
}

const VideoImageStyles = styled.div<Pick<VideoImageProps, 'aspectRatio'>>((props) => ({
  position: 'relative',
  cursor: 'pointer',
  overflow: 'hidden',
  ...(props.aspectRatio === 'fixed' && { display: 'inline-flex' }),

  '& .player-icon': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '3rem',
    transition: 'font-size var(--transitions-default)',
  },

  '&:hover .player-icon': {
    fontSize: '3.3rem',
  },
}))
const getUrl = (url: string, size = 'maxresdefault') => `https://i.ytimg.com/vi/${url}/${size}.jpg`

export const VideoImage = ({
  className,
  url,
  id,
  layout,
  image,
  width,
  height,
  aspectRatio,
  ...props
}: VideoImageProps) => {
  const [show, setShow] = useState(false)
  const youtubeVideoID = youtubeVideoIDParser(url)
  const imageAlt = (image && image.alt) || 'Poster do Vídeo'
  let videoImage: ImageProps = {
    source: image?.url,
    data: image,
  }

  if (!videoImage.source) {
    videoImage = {
      source: getUrl(youtubeVideoID, 'maxresdefault'),
      data: {
        mime_type: 'jpg',
        sizes: {
          medium: getUrl(youtubeVideoID, 'sddefault'),
          large: getUrl(youtubeVideoID),
        },
      },
    }
  }

  return (
    <>
      {show && (
        <YoutubeEmbed
          url={youtubeVideoID}
          id={id}
          width={width}
          height={height}
          aspectRatio={aspectRatio}
          className={className}
          {...props}
        />
      )}
      {!show && (
        <VideoImageStyles
          className={className}
          role="button"
          onClick={() => setShow(true)}
          aspectRatio={aspectRatio}
          aria-label="abrir vídeo"
        >
          <Image
            alt={imageAlt}
            layout={layout || 'responsive'}
            width={width || 352}
            height={height || 264}
            aria-hidden={true}
            {...videoImage}
          />

          <Icon icon="video-player" className="player-icon" aria-hidden />
        </VideoImageStyles>
      )}
    </>
  )
}
