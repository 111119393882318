import styled from '@emotion/styled'

import type { PropsWithChildren } from 'react'

import { clearBuilderProps } from '@/legacy/core/utils/dom/clearBuilderProps'
import { sanitizeUrl } from '@/legacy/core/utils/dom/security'

export interface YoutubeEmbedProps {
  url: string
  id?: string
  autoplay?: boolean
  aspectRatio?: '16x9' | '4x3' | 'fixed'
  width?: string | number
  height?: string | number
  className?: string
}

const YoutubeEmbedStyles = styled.div<Pick<YoutubeEmbedProps, 'aspectRatio' | 'width' | 'height'>>(
  (props) => ({
    overflow: 'hidden',
    padding: '75% 0px 0px',
    position: 'relative',
    height: 0,

    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    },

    ...(props.aspectRatio === '16x9' && { paddingTop: '56.25%' }),
    ...(props.aspectRatio === 'fixed' && {
      padding: 0,
      ...(!!props.width && { width: !isNaN(+props.width) ? `${props.width}px` : props.width }),
      ...(!!props.height && { height: !isNaN(+props.height) ? `${props.height}px` : props.height }),
    }),
  }),
)

const YoutubeEmbed = ({
  url,
  autoplay = true,
  aspectRatio = '4x3',
  ...props
}: PropsWithChildren<YoutubeEmbedProps>) => {
  clearBuilderProps(props)
  return (
    <YoutubeEmbedStyles aspectRatio={aspectRatio} {...props}>
      <iframe
        width="853"
        height="480"
        src={sanitizeUrl(
          `https://www.youtube.com/embed/${url}?fullscreen=1&${autoplay && 'autoplay=1'}`,
        )}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded YouTube video"
      />
    </YoutubeEmbedStyles>
  )
}

export default YoutubeEmbed
